/* istanbul ignore file */
import * as React from 'react';
import ReactCountryFlag from 'react-country-flag';

import { Icon } from 'elements';
import { ROUTES } from 'global/constants';
import { BannerList } from 'banners/components';
import { NotificationListContainer } from 'notifications/components';
import { NavLink } from 'react-router-dom';
import { SearchBox } from 'search/components/SearchBox';
import {
    Button,
    Dropdown,
    LabXChangeLogo,
    LoggedInMenu,
    ScreenReaderIntl,
    DropdownList,
} from 'ui/components';
import { WrappedMessage, showSearchBarInHero, isUserFromSouthAfrica, SA_COUNTRY_CODE } from 'utils';
import messages from '../displayMessages';
import { intl } from 'i18n';
import { EVENT_NAMES } from 'tracking/constants';
import { useAnalytics } from 'use-analytics';
import { analyticsInstance } from '../../../tracking';
import { authLocalStorageManager } from 'auth/utils';
import { ProfileRoleEnum } from 'labxchange-client';

// transparent is a variant of dark where no background image will be displayed
export type HeaderBackgroundStyle = 'dark' | 'transparent' | 'light';

export interface HeaderProps {
    backgroundStyle: HeaderBackgroundStyle;
    // set to null to set even use the default background url
    backgroundUrl?: string|null;
    backgroundClassrooms?: boolean;
    backgroundExplore?: boolean;
    username: string;
    onLogIn: () => void;
    onLogOut: () => void;
    onSignup: () => void;
    onShowSidebar: () => void;
    notificationCount: number;
    messageCount?: number;
    searchUrl: string;
    searchTitleUrl: string;
    children?: React.ReactNode;
    searchParams?: string[];
    searchTerm?: string;
    userRole?: null | string;
}

export interface HeaderState {
    scrollYPosition?: number;
    scrollYLastDirection: number;
    backgroundStyle: HeaderBackgroundStyle;
}

const LoggedInView: React.FC<HeaderProps> = (props: HeaderProps) => {
    const {onLogOut, username, notificationCount, searchUrl, searchTitleUrl, searchParams, searchTerm} = props;
    const iconFill = props.backgroundStyle === 'light' ? '#003E6B' : 'white';
    const isEducator = props.userRole === ProfileRoleEnum.Educator;

    return (
        <>
            {isUserFromSouthAfrica() ?
                <NavLink
                    className='header-nav-item hide-mobile curriculum-link'
                    activeClassName='active'
                    to={ROUTES.Curriculum.DETAIL_SLUG('caps')}
                    aria-label={intl.formatMessage(messages.uiNavCAPSCurriculumAria)}
                    onClick={() => analyticsInstance.track(
                        EVENT_NAMES.CurriculumHeaderButtonClicked,
                        {
                            button_text: messages.uiNavCurriculum.defaultMessage,
                            curriculum: 'caps',
                            country: (window as any).country,
                            url: window.location.toString()
                        }
                    )}
                >
                    <ReactCountryFlag countryCode={SA_COUNTRY_CODE} svg style={{ width: '28px', height: '20px', borderRadius: '2px' }} />
                    <span><WrappedMessage message={messages.uiNavCurriculum} /></span>
                    <span className='new-tag'><WrappedMessage message={messages.uiNavNewTag} /></span>
                </NavLink>
                :
                <NavLink
                    className='header-nav-item hide-mobile'
                    activeClassName='active'
                    to={ROUTES.Subjects}
                    onClick={() => {analyticsInstance.track(EVENT_NAMES.SubjectsPageHeaderButtonClicked, { button_text: messages.uiNavSubjects.defaultMessage, url: window.location.toString() });}}
                >
                    <WrappedMessage message={messages.uiNavSubjects} />
                </NavLink>
            }
            <NavLink
                className='header-nav-item hide-mobile'
                activeClassName='active'
                to={ROUTES.Library.HOME}
                onClick={() => analyticsInstance.track(EVENT_NAMES.LibraryPageHeaderButtonClicked, { button_text: messages.uiNavLibrary.defaultMessage, url: window.location.toString() })}
            >
                <WrappedMessage message={messages.uiNavLibrary} />
            </NavLink>
            {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
            <form className='search-bar form-inline mx-2' onFocus={() => analyticsInstance.track(EVENT_NAMES.SearchBarHeaderFormFocused, { form_placeholder: messages.uiNavSearch.defaultMessage, url: window.location.toString() })} >
                {/* We will hide the search bar from the header on the library,
                people, and sources pages, and show it instead in the hero section. */}
                {!showSearchBarInHero() &&
                    <SearchBox
                        searchUrl={searchUrl}
                        searchTitleUrl={searchTitleUrl}
                        darkBackground={props.backgroundStyle !== 'light'}
                        searchParams={searchParams}
                        searchTerm={searchTerm}
                        onSearchCallback={(searchKeyword: string) => {
                            analyticsInstance.track(
                                EVENT_NAMES.SearchBarHeaderIconClicked,
                                {
                                    search_keyword: searchKeyword,
                                    url: window.location.toString(),
                                },
                            );
                        }}
                    />
                }
            </form>
            <NavLink
                className='header-nav-item hide-mobile'
                activeClassName='active'
                to={isEducator ? ROUTES.Dashboard.EDUCATOR_CLASSROOMS : ROUTES.Dashboard.LEARNER_CLASSROOMS}
                exact={true}
                onClick={() => analyticsInstance.track(
                    EVENT_NAMES.MyClassesHeaderButtonClicked,
                    {
                        button_text: messages.uiNavMyClasses.defaultMessage,
                        url: window.location.toString()
                    }
                )}
            >
                <WrappedMessage message={messages.uiNavMyClasses}/>
            </NavLink>
            <NavLink
                className='header-nav-item hide-mobile'
                activeClassName='active'
                to={ROUTES.Dashboard.HOME}
                exact={true}
                onClick={() => analyticsInstance.track(
                    EVENT_NAMES.MyContentHeaderButtonClicked,
                    {
                        button_text: messages.uiNavMyContent.defaultMessage,
                        url: window.location.toString()
                    }
                )}
            >
                <WrappedMessage message={messages.uiNavMyContent}/>
            </NavLink>
            <Dropdown
                className='hide-mobile'
                id='header-notify-dropdown'
                label={<span className='icon-with-badge'>
                    <Icon name='bell' zoom='1.5em' outline fill={iconFill} />
                    {notificationCount > 0 &&
                        <span className='badge top'>
                            {notificationCount}
                            <ScreenReaderIntl
                                message={messages.uiNotificationsSR}
                                values={{count: notificationCount}}
                            />
                        </span>
                    }
                </span>}
                title={intl.formatMessage(messages.uiNotificationsButtonTitle)}
                onExpanded={(expanded) => expanded && analyticsInstance.track(EVENT_NAMES.NotificationsHeaderDropDownExpanded, { icon_title: messages.uiNotificationsButtonTitle.defaultMessage, url: window.location.toString() })}
            >
                <NotificationListContainer />
            </Dropdown>
            <LoggedInMenu
                className='hide-mobile'
                onLogOut={onLogOut}
                username={username}
            />
        </>
    );
};

const LoggedOutView: React.FC<HeaderProps> = (props: HeaderProps) => {
    const analytics = useAnalytics();
    const [isEducatorsDropdownExpanded, setIsEducatorsDropdownExpanded] = React.useState(false);
    const [isLearnersDropdownExpanded, setIsLearnersDropdownExpanded] = React.useState(false);

    const handleEducatorsDropdownExpandedChange = (expanded: boolean) => {
        setIsEducatorsDropdownExpanded(expanded);
        if (expanded) {
            analytics.track(
                EVENT_NAMES.EducatorsHeaderDropdownExpanded,
                {
                    button_text: messages.uiNavEducatorsDropdownLabel.defaultMessage,
                    url: window.location.toString()
                }
            );
        }
    };

    const handleLearnersDropdownExpandedChange = (expanded: boolean) => {
        setIsLearnersDropdownExpanded(expanded);
        if (expanded) {
            analytics.track(
                EVENT_NAMES.LearnersHeaderDropdownExpanded,
                {
                    button_text: messages.uiNavLearnersDropdownLabel.defaultMessage,
                    url: window.location.toString()
                }
            );
        }
    };

    return (
    <>
        {isUserFromSouthAfrica() ?
            <NavLink
                className='header-nav-item hide-mobile curriculum-link'
                activeClassName='active'
                to={ROUTES.Curriculum.DETAIL_SLUG('caps')}
                onClick={() => analyticsInstance.track(
                    EVENT_NAMES.CurriculumHeaderButtonClicked,
                    { button_text: messages.uiNavCurriculum.defaultMessage, url: window.location.toString() }
                )}
            >
                <ReactCountryFlag countryCode={SA_COUNTRY_CODE} svg style={{ width: '28px', height: '20px', borderRadius: '2px' }} />
                <span><WrappedMessage message={messages.uiNavCurriculum} /></span>
                <span className='new-tag'><WrappedMessage message={messages.uiNavNewTag} /></span>
            </NavLink>
            :
            <NavLink
                className='header-nav-item hide-mobile'
                activeClassName='active'
                to={ROUTES.Subjects}
                onClick={() => {analyticsInstance.track(EVENT_NAMES.SubjectsPageHeaderButtonClicked, { button_text: messages.uiNavSubjects.defaultMessage, url: window.location.toString() });}}
            >
                <WrappedMessage message={messages.uiNavSubjects} />
            </NavLink>
        }
        <NavLink
            className='header-nav-item hide-mobile hide-tablet'
            activeClassName='active'
            to={ROUTES.Library.HOME}
            onClick={() => analyticsInstance.track(EVENT_NAMES.LibraryPageHeaderButtonClicked, { button_text: messages.uiNavLibrary.defaultMessage, url: window.location.toString() })}
        >
            <WrappedMessage message={messages.uiNavLibrary} />
        </NavLink>
        {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
        <form className='search-bar form-inline mx-2' onFocus={() => analyticsInstance.track(EVENT_NAMES.SearchBarHeaderFormFocused, { form_placeholder: messages.uiNavSearch.defaultMessage, url: window.location.toString() })} >
            {/* We will hide the search bar from the header on the library,
            people, and sources pages, and show it instead in the hero section. */}
            {!showSearchBarInHero() &&
                <SearchBox
                searchUrl={props.searchUrl}
                searchTitleUrl={props.searchTitleUrl}
                darkBackground={props.backgroundStyle !== 'light'}
                searchParams={props.searchParams}
                searchTerm={props.searchTerm}
                onSearchCallback={(searchKeyword: string) => {
                    analyticsInstance.track(
                        EVENT_NAMES.SearchBarHeaderIconClicked,
                        {
                            search_keyword: searchKeyword,
                            url: window.location.toString(),
                        },
                    );
                }}
            />
            }
        </form>
        {/* eslint-disable jsx-a11y/click-events-have-key-events */}
        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
        <DropdownList
            id='educators-dropdown'
            label={
                <div className='header-dropdown-label'>
                    <WrappedMessage message={messages.uiNavEducatorsDropdownLabel} />
                    <Icon name={isEducatorsDropdownExpanded ? 'chevron-up' : 'chevron-down'} />
                </div>
            }
            className='header-nav-dropdown hide-mobile'
            onExpandedChange={handleEducatorsDropdownExpandedChange}
            links={[
                {
                    key: 'educators-explore',
                    to: ROUTES.Teach,
                    content: <>
                        <WrappedMessage message={messages.uiNavEducatorsDropdownExploreContent}/>
                    </>,
                    disableActive: true,
                    onClick: () => analytics.track(
                        EVENT_NAMES.EducatorsHeaderDropdownExploreClicked,
                        {
                            button_text: messages.uiNavEducatorsDropdownExploreContent.defaultMessage,
                            url: window.location.toString()
                        }
                    )
                },
                {
                    key: 'educators-create-class',
                    to: ROUTES.Dashboard.HOME,
                    content: <>
                        <WrappedMessage message={messages.uiNavEducatorsDropdownCreateClass}/>
                    </>,
                    disableActive: true,
                    onClick: () => analytics.track(
                        EVENT_NAMES.EducatorsHeaderDropdownCreateClassClicked,
                        {
                            button_text: messages.uiNavEducatorsDropdownCreateClass.defaultMessage,
                            url: window.location.toString()
                        }
                    )
                },
                {
                    key: 'educators-create-content',
                    to: ROUTES.Dashboard.HOME,
                    className: 'dropdown-button',
                    content: <>
                        <Button
                            btnStyle='outline'
                            label={messages.uiNavEducatorsDropdownCreateContent}
                            icon='plus'
                            iconPosition='right'
                        />
                    </>,
                    disableActive: true,
                    onClick: () => analytics.track(
                        EVENT_NAMES.EducatorsHeaderDropdownCreateContentClicked,
                        {
                            button_text: messages.uiNavEducatorsDropdownCreateContent.defaultMessage,
                            url: window.location.toString()
                        }
                    )
                }
            ]}
            expandOnHover={true}
        />
        <DropdownList
            id='learners-dropdown'
            label={
                <div className='header-dropdown-label'>
                    <WrappedMessage message={messages.uiNavLearnersDropdownLabel} />
                    <Icon name={isLearnersDropdownExpanded ? 'chevron-up' : 'chevron-down'} />
                </div>
            }
            className='header-nav-dropdown hide-mobile'
            onExpandedChange={handleLearnersDropdownExpandedChange}
            links={[
                {
                    key: 'learners-create-content',
                    to: ROUTES.Dashboard.HOME,
                    content: <>
                        <WrappedMessage message={messages.uiNavLearnersDropdownCreateContent}/>
                    </>,
                    disableActive: true,
                    onClick: () => analytics.track(
                        EVENT_NAMES.LearnersHeaderDropdownCreateContentClicked,
                        {
                            button_text: messages.uiNavLearnersDropdownCreateContent.defaultMessage,
                            url: window.location.toString()
                        }
                    )
                },
                {
                    key: 'learners-join-class',
                    className: 'dropdown-button',
                    to: ROUTES.Dashboard.LEARNER_CLASSROOMS,
                    content: <>
                        <Button
                            btnStyle='outline'
                            label={messages.uiNavLearnersDropdownJoinAClass}
                        />
                    </>,
                    disableActive: true,
                    onClick: () => analytics.track(
                        EVENT_NAMES.LearnersHeaderDropdownJoinClassClicked,
                        {
                            button_text: messages.uiNavLearnersDropdownJoinAClass.defaultMessage,
                            url: window.location.toString()
                        }
                    )
                }
            ]}
            expandOnHover={true}
        />
        <div className='separator hide-tablet'/>
        <Button
            className='login-btn hide-mobile tablet-login-btn'
            btnStyle='outline'
            onClick={() => {
                analytics.track(EVENT_NAMES.SignInButtonClicked, { url: window.location.toString() });
                props.onLogIn();
            }}
            label={messages.uiSignIn}
        />
        <Button
            className='signup-btn hide-mobile hide-tablet'
            btnStyle='primary'
            onClick={() => {
                analytics.track(EVENT_NAMES.SignUpButtonClicked, { url: window.location.toString() });
                props.onSignup();
            }}
            label={messages.uiSignUp}
        />
    </>
    );
};

export class Header extends React.PureComponent<HeaderProps, HeaderState> {

    constructor(props: any) {
        super(props);
        this.state = {
            scrollYPosition: 0,
            scrollYLastDirection: 0,
            backgroundStyle: this.props.backgroundStyle,
        };
    }

    public updateColorOnScrollForClassName(className: string, originalColor: string, scrollPosition: number) {
        const elements = document.getElementsByClassName(className) as HTMLCollectionOf<HTMLElement>;
        let color = originalColor;
        /* can't use for-of for the for loop below because */
        /* HTMLCollectionOf<HTMLElement> does not support it */
        /* tslint:disable-next-line */
        for (let i = 0; i < elements.length; i++) {
            if (elements[i]) {
                if (scrollPosition !== 0) {
                    color = '#003E6B';
                }
                elements[i].style.color = color;
                if (className === 'login-btn') {
                    elements[i].style.borderColor = color;
                }
            }
        }
    }

    public componentDidMount() {
        const headerNavItemClassName = 'header-nav-item';
        const headerNavItem = document.getElementsByClassName(headerNavItemClassName) as HTMLCollectionOf<HTMLElement>;
        const originalColorHeaderNavItem =  headerNavItem[0]?.style.color;
        const searchBoxInputClassName = 'search-box-input';
        const searchBoxInput = document.getElementsByClassName(searchBoxInputClassName) as HTMLCollectionOf<HTMLElement>;
        const originalColorSearchBoxInput =  searchBoxInput[0]?.style.color;
        const loginButtonClassName = 'login-btn';
        const loginButton = document.getElementsByClassName(loginButtonClassName) as HTMLCollectionOf<HTMLElement>;
        const originalColorLoginButton =  loginButton[0]?.style.color;

        document.addEventListener('scroll',
            (event) => {
                const target = event.target as HTMLDocument;
                const scrollYPosition = target?.scrollingElement?.scrollTop!;
                const scrollYLastDirection = scrollYPosition - this.state.scrollYPosition!;
                this.setState({scrollYPosition, scrollYLastDirection}, () => {
                    const backgroundStyle = (this.state.scrollYPosition === 0 || this.state.scrollYLastDirection > 0) ? this.props.backgroundStyle : 'light';
                    this.setState({backgroundStyle});
                });

                this.updateColorOnScrollForClassName(headerNavItemClassName, originalColorHeaderNavItem, scrollYPosition);
                this.updateColorOnScrollForClassName(searchBoxInputClassName, originalColorSearchBoxInput, scrollYPosition);
                this.updateColorOnScrollForClassName(loginButtonClassName, originalColorLoginButton, scrollYPosition);
            }
        );
    }

    public render() {
        // The default hexagonal background does not need to be positioned or change blending.
        // Designs for classroom page header show some sort of fade, so background-blend-mode is used,
        // So that text is still readable with high contrast images set
        const extraStyles: any = {};
        if (this.props.backgroundClassrooms) {
            extraStyles.backgroundPosition = 'center';
            extraStyles.backgroundBlendMode = 'soft-light';
        }

        if (this.props.backgroundStyle === 'dark' && this.props.backgroundUrl !== null) {
            extraStyles.backgroundImage = `url(${this.props.backgroundUrl || '/assets/images/background-blue-desktop.svg'})`;
            extraStyles.backgroundSize = 'cover';
        }

        return (
            <header
                className={`global-header ${this.props.backgroundExplore ? 'dark' : this.state.backgroundStyle}`}
                style={extraStyles}
            >
                <BannerList sticky={this.state.scrollYPosition! > 0 ? (this.state.scrollYLastDirection < 0 ? true : true) : false}/>
                <div className='global-nav nav-placeholder'/>
                <nav className={`global-nav navbar ${this.state.scrollYPosition! > 0 ? (this.state.scrollYLastDirection < 0 ? 'scroll-up' : 'scroll-down') : ''}`}>
                    <button className='mobile-grabber btn btn-link' onClick={this.props.onShowSidebar} aria-label={intl.formatMessage(messages.hamburgerMenuAriaLabel)}>
                    <span className='icon-with-badge'>
                        <Icon
                            name='grabber'
                            zoom='2em'
                            fill={this.state.backgroundStyle === 'light' ? '#003E6B' : 'white'}
                        />
                        {this.props.notificationCount > 0 &&
                        <span className='badge bottom'>
                                {this.props.notificationCount}
                            <ScreenReaderIntl
                                message={messages.uiNotificationsSR}
                                values={{count: this.props.notificationCount}}
                            />
                            </span>
                        }
                    </span>
                    </button>
                    <NavLink
                        className='logo-link'
                        to={authLocalStorageManager.role === 'educator' ? ROUTES.Teach : ROUTES.All.HOME}
                        onClick={() => analyticsInstance.track(
                            EVENT_NAMES.HeaderLabXLogoClicked,
                            {
                                button_text: messages.uiHeaderNavBarBrand.defaultMessage,
                                url: window.location.toString()
                            }
                        )}
                    >
                        <LabXChangeLogo type='trim' theme={this.state.backgroundStyle === 'light' ? 'dark' : 'light'}/>
                    </NavLink>
                    {this.props.username
                        ? <LoggedInView {...this.props} backgroundStyle={this.state.backgroundStyle}/>
                        : <LoggedOutView {...this.props} backgroundStyle={this.state.backgroundStyle}/>
                    }
                </nav>
                {this.props.children}
            </header>
        );
    }
}
