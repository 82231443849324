import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import {
  CurriculumSubject,
  ModuleHierarchy,
  Module,
} from 'labxchange-client';
import { Collapsible } from '@labxchange/ui-components';

import { SelectDropdownOption } from './types';

export const createSubjectGradeOptions = (
  subjects: CurriculumSubject[], selectedSubjectValue: string
) => {
  const subjectOptArray: SelectDropdownOption[] = [];
  const gradeOptArray: SelectDropdownOption[] = [];

  subjects.map((s) => {
    if (s.slug && s.name && s.isPublic) {
      subjectOptArray.push({
        value: s.slug,
        label: s.name,
      });

      if (s.slug === selectedSubjectValue) {
        s.grades?.map((g) => {
          if (g.slug && g.name) {
            gradeOptArray.push({
              value: g.slug,
              label: g.name,
            });
          }
        });
      }
    }
  });

  return { subjectOptArray, gradeOptArray };
};

export const renderModules = (
  modules: ModuleHierarchy[], curriculum: string, subject: string,
  grade: string, moduleLevel: number, activeModule?: string,
  callback?: (module: Module) => void
) => {
  if (!modules || !modules.length) {
      return;
  }

  if (moduleLevel === 1) {
    return (
      <>
        {modules.map((module:any) => (
          <div key={`${module.slug}-${module.expanded}`} className='curriculum-module-container'>
            <h3 className='curriculum-module-heading'> {module.name} </h3>
            <ul key={module.slug} className='curriculum-module-list'>
              {renderModules(
                module.subModules || module.sub_modules, curriculum,
                subject, grade, moduleLevel + 1, activeModule, callback
              )}
            </ul>
          </div>
        ))}
      </>
    );
  }

  return (
      <>
        {modules.map((module:any) => (
          module.subModules?.length || module.sub_modules?.length ? (
            <li key={`${module.slug}-${module.expanded}`} className='curriculum-module-list'>
              <Collapsible title={module.name} showMarkers={true} isOpen={module.expanded || false} isCollapsible={true}>
                <ul key={module.slug} className='curriculum-module-list'>
                  {renderModules(
                    module.subModules || module.sub_modules, curriculum,
                    subject, grade, moduleLevel + 1, activeModule, callback
                  )}
                </ul>
              </Collapsible>
            </li>
          ) : (
            <li key={module.slug} className='curriculum-module-item'>
              <Link
                onClick={() => callback && callback(module)}
                to={`/c/${curriculum}/${subject}/${grade}/${module.slug}${window.location.search}`}
                className={classNames(
                  'curriculum-topic-link',
                  { active: module.slug === activeModule }
                )}
              >
                <span className='curriculum-topic-link-text'>{module.name}</span>
              </Link>
            </li>
          )
        ))}
      </>
    );
};

export const expandActiveModulePath = (
  modules: ModuleHierarchy[],
  activeModule: string
) => {
  const markExpanded = (currentModules: ModuleHierarchy[]): { expandedModules: ModuleHierarchy[]; foundActive: boolean } => {
    let foundActiveInCurrentLevel = false;

    const updatedModules = currentModules.map((module: any) => {
      const subModules = module.subModules || module.sub_modules || [];
      const isActiveModule = module.slug === activeModule;

      const { expandedModules: updatedSubModules, foundActive } = markExpanded(subModules);

      const isExpanded = isActiveModule || foundActive;

      if (isActiveModule || foundActive) {
        foundActiveInCurrentLevel = true;
      }

      return {
        ...module,
        expanded: isExpanded,
        subModules: updatedSubModules,
      };
    });

    return { expandedModules: updatedModules, foundActive: foundActiveInCurrentLevel };
  };

  const { expandedModules } = markExpanded(modules);
  return expandedModules;
};

export const searchModules = (
  modules: ModuleHierarchy[],
  searchKeyword: string
): ModuleHierarchy[] => {
  const searchValue = searchKeyword.toLowerCase().trim();

  const filterModules = (currentModules: ModuleHierarchy[]): ModuleHierarchy[] => {
    return currentModules
      .map((module: any) => {
        const subModules = module.subModules || module.sub_modules || [];
        const matchesCurrentModule = module.name.toLowerCase().includes(searchValue);

        // If the current module matches, expand it and all its children
        if (matchesCurrentModule) {
          return {
            ...module,
            expanded: true,
            subModules: subModules.map((subModule: any) => ({
              ...subModule,
              expanded: true, // Ensure all submodules are expanded
            })),
          };
        }

        // Recursively filter and expand matching submodules
        const filteredSubModules = filterModules(subModules);
        if (filteredSubModules.length > 0) {
          return {
            ...module,
            expanded: true,
            subModules: filteredSubModules,
          };
        }

        // If no match, exclude the current module
        return null;
      })
      .filter((module) => module !== null);
  };

  return filterModules(modules);
};
